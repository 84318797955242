import { createSlice } from "@reduxjs/toolkit";
import sum from "lodash/sum";
import uniqBy from "lodash/uniqBy";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  operators: [],
  page: 1,
  allItems: 0,
};

const slice = createSlice({
  name: "operator",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET OPERATORS
    getOperatorsSuccess(state, action) {
      state.isLoading = false;
      state.operators = action.payload.option;
      state.page = action.payload.page;
      state.allItems = action.payload.all_items;
    },

    // CREATE OPERATOR
    createOperatorSuccess(state, action) {
      state.isLoading = false;
      state.operators = action.payload.option;
      state.page = action.payload.page;
      state.allItems = action.payload.all_items;
    },

    // DELETE OPERATOR
    deleteOperatorSuccess(state, action) {
      state.isLoading = false;
      state.operators = action.payload.option;
      state.page = action.payload.page;
      state.allItems = action.payload.all_items;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getOperators(page) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/userlist?page=${page}`);
      dispatch(slice.actions.getOperatorsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createOperator(data, successCB) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/api/create_user.php", data);
      dispatch(slice.actions.createOperatorSuccess(response.data));
      successCB();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteOperator(data, successCB) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/api/user/delete", data);
      dispatch(slice.actions.deleteOperatorSuccess(response.data));
      successCB();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
