import { createSlice } from "@reduxjs/toolkit";
import sum from "lodash/sum";
import uniqBy from "lodash/uniqBy";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  photozones: [],
  allphotozones: [],
  page: 1,
  allItems: 0,
};

const slice = createSlice({
  name: "photozone",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PHOTOZONES
    getPhotozonesSuccess(state, action) {
      state.isLoading = false;
      state.photozones = action.payload.option;
      state.page = action.payload.page;
      state.allItems = action.payload.all_items;
    },

    // GET ALL PHOTOZONES
    getAllPhotozonesSuccess(state, action) {
      state.isLoading = false;
      state.allphotozones = action.payload.option;
    },

    // CREATE PHOTOZONE
    createPhotozoneSuccess(state, action) {
      state.isLoading = false;
      state.photozones = action.payload.option;
      state.page = action.payload.page;
      state.allItems = action.payload.all_items;
    },

    // UPDATE PHOTOZONE
    updatePhotozoneSuccess(state, action) {
      state.isLoading = false;
      state.photozones = action.payload.option;
      state.page = action.payload.page;
      state.allItems = action.payload.all_items;
    },

    // DELETE PHOTOZONE
    deletePhotozoneSuccess(state, action) {
      state.isLoading = false;
      state.photozones = action.payload.option;
      state.page = action.payload.page;
      state.allItems = action.payload.all_items;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPhotozones(page) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/zonelist?page=${page}`);
      dispatch(slice.actions.getPhotozonesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAllPhotozones(page) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/allzonelist`);
      dispatch(slice.actions.getAllPhotozonesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createPhotozone(data, successCB) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/api/photozone/create", data);
      dispatch(slice.actions.createPhotozoneSuccess(response.data));
      successCB();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updatePhotozone(data, successCB) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/api/photozone/update", data);
      dispatch(slice.actions.updatePhotozoneSuccess(response.data));
      successCB();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deletePhotozone(zone_id, successCB) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `/api/photozone/delete?zone_id=${zone_id}`
      );
      dispatch(slice.actions.deletePhotozoneSuccess(response.data));
      successCB();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
