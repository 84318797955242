// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// components
import Label from "../../../components/Label";
import SvgIconStyle from "../../../components/SvgIconStyle";
import {
  Camera,
  Groups,
  Map,
  PriceCheck,
  ViewList,
  Web,
} from "@mui/icons-material";

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: "1", height: 1 }} />
);

const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  booking: getIcon("ic_booking"),
  invoice: getIcon("ic_invoice"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
};

const navConfig = [
  // Админ
  // ----------------------------------------------------------------------
  {
    items: [
      {
        title: "Фотозоны",
        path: PATH_DASHBOARD.admin.photozones,
        icon: <Map />,
        roles: ["1", "2", "3"],
      },
      {
        title: "Блоки",
        path: PATH_DASHBOARD.admin.blocks,
        icon: <Web />,
        roles: ["1", "2", "3"],
      },

      {
        title: "Фотостудии",
        path: PATH_DASHBOARD.admin.photostudios,
        icon: <Camera />,
        roles: ["1", "2", "3"],
      },
      {
        title: "Клиенты",
        path: PATH_DASHBOARD.admin.orders,
        icon: <ViewList />,
        roles: ["1", "2", "3"],
      },
      {
        title: "Авансы",
        path: PATH_DASHBOARD.admin.advances,
        icon: <PriceCheck />,
        roles: ["1", "2", "3"],
      },
      // {
      //   title: "Отчёты",
      //   path: PATH_DASHBOARD.admin.reports,
      //   icon: ICONS.ecommerce,
      // },
      {
        title: "Операторы",
        path: PATH_DASHBOARD.admin.operators,
        icon: <Groups />,
        roles: ["1", "3"],
      },
    ],
  },

  // Оператор
  // ----------------------------------------------------------------------
  // {
  //   subheader: "Оператор",
  //   items: [
  //     {
  //       title: "Фотостудии",
  //       path: PATH_DASHBOARD.operator.photostudios,
  //       icon: ICONS.ecommerce,
  //     },
  //     {
  //       title: "Заказы",
  //       path: PATH_DASHBOARD.operator.orders,
  //       icon: ICONS.ecommerce,
  //     },
  //     {
  //       title: "Авансы",
  //       path: PATH_DASHBOARD.operator.advances,
  //       icon: ICONS.ecommerce,
  //     },
  //   ],
  // },

  // Management
  // ----------------------------------------------------------------------
  // {
  //   subheader: "Management",
  //   items: [
  //     // USER
  //     {
  //       title: "user",
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: "profile", path: PATH_DASHBOARD.user.profile },
  //         { title: "cards", path: PATH_DASHBOARD.user.cards },
  //         { title: "list", path: PATH_DASHBOARD.user.list },
  //         { title: "create", path: PATH_DASHBOARD.user.new },
  //         { title: "edit", path: PATH_DASHBOARD.user.demoEdit },
  //         { title: "account", path: PATH_DASHBOARD.user.account },
  //       ],
  //     },

  //     // E-COMMERCE
  //     {
  //       title: "e-commerce",
  //       path: PATH_DASHBOARD.eCommerce.root,
  //       icon: ICONS.cart,
  //       children: [
  //         { title: "shop", path: PATH_DASHBOARD.eCommerce.shop },
  //         { title: "product", path: PATH_DASHBOARD.eCommerce.demoView },
  //         { title: "list", path: PATH_DASHBOARD.eCommerce.list },
  //         { title: "create", path: PATH_DASHBOARD.eCommerce.new },
  //         { title: "edit", path: PATH_DASHBOARD.eCommerce.demoEdit },
  //         { title: "checkout", path: PATH_DASHBOARD.eCommerce.checkout },
  //       ],
  //     },

  //     // INVOICE
  //     {
  //       title: "invoice",
  //       path: PATH_DASHBOARD.invoice.root,
  //       icon: ICONS.invoice,
  //       children: [
  //         { title: "list", path: PATH_DASHBOARD.invoice.list },
  //         { title: "details", path: PATH_DASHBOARD.invoice.demoView },
  //         { title: "create", path: PATH_DASHBOARD.invoice.new },
  //         { title: "edit", path: PATH_DASHBOARD.invoice.demoEdit },
  //       ],
  //     },

  //     // BLOG
  //     {
  //       title: "blog",
  //       path: PATH_DASHBOARD.blog.root,
  //       icon: ICONS.blog,
  //       children: [
  //         { title: "posts", path: PATH_DASHBOARD.blog.posts },
  //         { title: "post", path: PATH_DASHBOARD.blog.demoView },
  //         { title: "create", path: PATH_DASHBOARD.blog.new },
  //       ],
  //     },
  //   ],
  // },

  // APP
  // ----------------------------------------------------------------------
  // Need help?
];

export default navConfig;
