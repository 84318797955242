import { createSlice } from "@reduxjs/toolkit";
import sum from "lodash/sum";
import uniqBy from "lodash/uniqBy";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  photostudios: [],
  allphotostudios: [],
  page: 1,
  allItems: 0,
};

const slice = createSlice({
  name: "photostudio",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PHOTOSTUDIOS
    getPhotostudiosSuccess(state, action) {
      state.isLoading = false;
      state.photostudios = action.payload.option;
      state.page = action.payload.page;
      state.allItems = action.payload.all_items;
    },

    // GET ALL PHOTOSTUDIOS
    getAllPhotostudiosSuccess(state, action) {
      state.isLoading = false;
      state.allphotostudios = action.payload.option;
    },

    // CREATE PHOTOSTUDIO
    createPhotostudioSuccess(state, action) {
      state.isLoading = false;
      state.photostudios = action.payload.option;
      state.page = action.payload.page;
      state.allItems = action.payload.all_items;
    },

    // UPDATE PHOTOSTUDIO
    updatePhotostudioSuccess(state, action) {
      state.isLoading = false;
      state.photostudios = action.payload.option;
      state.page = action.payload.page;
      state.allItems = action.payload.all_items;
    },

    // DELETE PHOTOSTUDIO
    deletePhotostudioSuccess(state, action) {
      state.isLoading = false;
      state.photostudios = action.payload.option;
      state.page = action.payload.page;
      state.allItems = action.payload.all_items;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPhotostudios(page) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/studiolist?page=${page}`);
      dispatch(slice.actions.getPhotostudiosSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createPhotostudio(data, successCB) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/api/studio/create", data);
      dispatch(slice.actions.createPhotostudioSuccess(response.data));
      successCB();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updatePhotostudio(data, successCB) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/api/studio/update", data);
      dispatch(slice.actions.updatePhotostudioSuccess(response.data));
      successCB();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deletePhotostudio(data, successCB) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/api/studio/delete", data);
      dispatch(slice.actions.deletePhotostudioSuccess(response.data));
      successCB();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAllPhotostudios(page) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/allstudiolist`);
      dispatch(slice.actions.getAllPhotostudiosSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
