import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// slices
import mailReducer from "./slices/mail";
import chatReducer from "./slices/chat";
import productReducer from "./slices/product";
import calendarReducer from "./slices/calendar";
import kanbanReducer from "./slices/kanban";
import blockReducer from "./slices/block";
import photozoneReducer from "./slices/photozone";
import photostudioReducer from "./slices/photostudio";
import operatorReducer from "./slices/operator";
import advanceReducer from "./slices/advance";
import orderReducer from "./slices/order";

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: [],
};

const productPersistConfig = {
  key: "product",
  storage,
  keyPrefix: "redux-",
  whitelist: ["sortBy", "checkout"],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  block: blockReducer,
  photozone: photozoneReducer,
  photostudio: photostudioReducer,
  operator: operatorReducer,
  advance: advanceReducer,
  order: orderReducer,
  // product: persistReducer(productPersistConfig, productReducer),
});

export { rootPersistConfig, rootReducer };
