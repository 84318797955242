import { createSlice } from "@reduxjs/toolkit";
import sum from "lodash/sum";
import uniqBy from "lodash/uniqBy";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  orders: [],
  page: 1,
  allItems: 0,
};

const slice = createSlice({
  name: "order",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ORDERS
    getOrdersSuccess(state, action) {
      state.isLoading = false;
      state.orders = action.payload.option;
      state.page = action.payload.page;
      state.allItems = action.payload.all_items;
    },

    // CREATE ORDERS
    createOrderSuccess(state, action) {
      state.isLoading = false;
      state.orders = action.payload.option;
      state.page = action.payload.page;
      state.allItems = action.payload.all_items;
    },

    // DELETE ORDER
    deleteOrderSuccess(state, action) {
      state.isLoading = false;
      state.orders = action.payload.option;
      state.page = action.payload.page;
      state.allItems = action.payload.all_items;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getOrders(page) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/orderlist?page=${page}`);
      dispatch(slice.actions.getOrdersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createOrder(data, successCB) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/api/order/create", data);
      dispatch(slice.actions.createOrderSuccess(response.data));
      successCB();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteOrder(id, successCB) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/order/delete?orders_id=${id}`);
      dispatch(slice.actions.deleteOrderSuccess(response.data));
      successCB();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createFakeInvoice(data, successCB) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axios.post("/fake/invoice", data);

      successCB();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
