import { enqueueSnackbar } from "notistack";

const handleAxiosError = (error) => {
  const { response } = error;

  if (response) {
    // The request was made and the server responded with a status code
    const { data, status } = response;

    // Customize the error message as needed
    const errorMessage = data.message || "Произошла ошибка.";

    enqueueSnackbar(errorMessage, {
      variant: "error",
    });
  } else {
    // The request was not made or the server did not respond
    enqueueSnackbar(
      "Ошибка сети. Пожалуйста, проверьте ваше интернет-соединение.",
      {
        variant: "error",
      }
    );
  }
};

export default handleAxiosError;
