import { createSlice } from "@reduxjs/toolkit";
import sum from "lodash/sum";
import uniqBy from "lodash/uniqBy";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  advances: [],
  activeAdvances: [],
  page: 1,
  allItems: 0,
};

const slice = createSlice({
  name: "advance",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ADVANCES
    getAdvancesSuccess(state, action) {
      state.isLoading = false;
      state.advances = action.payload.option;
      state.page = action.payload.page;
      state.allItems = action.payload.all_items;
    },

    // GET ACTIVE ADVANCES
    getActiveAdvancesSuccess(state, action) {
      state.isLoading = false;
      state.activeAdvances = action.payload;
    },

    // CREATE ADVANCE
    createAdvanceSuccess(state, action) {
      state.isLoading = false;
      state.advances = action.payload.option;
      state.page = action.payload.page;
      state.allItems = action.payload.all_items;
    },

    // DELETE ADVANCE
    deleteAdvanceSuccess(state, action) {
      state.isLoading = false;
      state.advances = action.payload.option;
      state.page = action.payload.page;
      state.allItems = action.payload.all_items;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAdvances(page) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/prepaidlist?page=${page}`);
      dispatch(slice.actions.getAdvancesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getActiveAdvances() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get("/api/activeprepaid");

      dispatch(slice.actions.getActiveAdvancesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createAdvance(data, successCB) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/api/prepaid/create", data);
      dispatch(slice.actions.createAdvanceSuccess(response.data));
      successCB();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteAdvance(id, successCB) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/prepaid/delete?prepaid_id=${id}`);
      dispatch(slice.actions.deleteAdvanceSuccess(response.data));
      successCB();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
