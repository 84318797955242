import axios from "axios";
// config
import { HOST_API } from "../config";
import handleAxiosError from "./handleAxiosError";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    handleAxiosError(error);

    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    );
  }
);

export default axiosInstance;
