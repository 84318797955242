import { createSlice } from "@reduxjs/toolkit";
import sum from "lodash/sum";
import uniqBy from "lodash/uniqBy";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  blocks: [],
  allblocks: [],
  page: 1,
  allItems: 0,
};

const slice = createSlice({
  name: "block",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET BLOCKS
    getBlocksSuccess(state, action) {
      state.isLoading = false;
      state.blocks = action.payload.option;
      state.page = action.payload.page;
      state.allItems = action.payload.all_items;
    },

    // GET ALL BLOCKS
    getAllBlocksSuccess(state, action) {
      state.isLoading = false;
      state.allblocks = action.payload.option;
    },

    // CREATE BLOCK
    createBlockSuccess(state, action) {
      state.isLoading = false;
      state.blocks = action.payload.option;
      state.page = action.payload.page;
      state.allItems = action.payload.all_items;
    },

    // ADD ZONE TO BLOCK
    addZoneToBlockSuccess(state, action) {
      state.isLoading = false;
      const blockIdx = state.blocks.findIndex(
        (block) => block.block_id == action.payload.block_id
      );

      state.blocks[blockIdx].zones.unshift(action.payload.newZone);
    },

    // REMOVE ZONE FROM BLOCK
    removeZoneFromBlockSuccess(state, action) {
      state.isLoading = false;
      const blockIdx = state.blocks.findIndex(
        (block) => block.block_id == action.payload.block_id
      );
      const zoneIdx = state.blocks[blockIdx].zones.findIndex(
        (zone) => zone.zone_id == action.payload.zone_id
      );

      state.blocks[blockIdx].zones.splice(zoneIdx, 1);
    },

    // DELETE BLOCK
    deleteBlockSuccess(state, action) {
      state.isLoading = false;
      state.blocks = action.payload.option;
      state.page = action.payload.page;
      state.allItems = action.payload.all_items;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getBlocks(page) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/blocklist?page=${page}`);
      dispatch(slice.actions.getBlocksSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createBlock(data, successCB) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/api/block/create", data);
      dispatch(slice.actions.getBlocksSuccess(response.data));
      successCB();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addZoneToBlock(data, newZone, successCB) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/api/block/addzone", data);
      dispatch(
        slice.actions.addZoneToBlockSuccess({
          block_id: response.data.block_id,
          newZone,
        })
      );
      successCB();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function removeZoneFromBlock(data, successCB) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post("/api/block/removezone", data);
      dispatch(slice.actions.removeZoneFromBlockSuccess(response.data));
      successCB();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteBlock(block_id, successCB) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(
        `/api/block/delete?block_id=${block_id}`
      );
      dispatch(slice.actions.deleteBlockSuccess(response.data));
      successCB();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAllBlocks(page) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/allblocklist`);
      dispatch(slice.actions.getAllBlocksSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
